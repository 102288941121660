@import '../../mixins';

.send-review {
  display: flex;
  flex-direction: column;
  gap: rem(25);

  @include mediaBigDesktop {
    gap: big(25);
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: rem(25);

    @include mediaBigDesktop {
      gap: big(25);
    }

    @include mediaMobile {
      gap: rem(20);
    }
  }

  &__input {
    display: flex;
    flex-direction: column;
    gap: rem(20);

    @include mediaBigDesktop {
      gap: big(20);
    }

    @include mediaLaptop {
      gap: rem(15);
    }
  }

  &__submit {
    width: 100%;
  }
}
